<template>
  <section>
    <section class="container-header">
      <section class="text-container">
        <h3 class="title">{{ beneficiary.name }}</h3>
      </section>
      <div 
        v-can="'RepMedRel3'"
        class="btn-title-container"
      >
        <b-button 
          v-if="!generateBySelection"
          variant="outline-primary"
          @click="startGenerateBySelection"
        >
          Selecionar procedimentos/items
        </b-button>

        <b-button
          v-else
          variant="outline-primary"
          @click="selecteds.length ? generateExtract() : ''"
        >
          {{selecteds.length === 0 ? 'Selecione e gerar extrato' : 'Gerar extrato ('+ selecteds.length +' selecionado'+ (selecteds.length > 1 ? 's)' : ')')}}
        </b-button>
      </div>
    </section>
    <hr />
  </section>
</template>

<script>
import { getCurrentClinic, userHasPermission } from '@/utils/localStorageManager'

export default {
  props: {
    beneficiary: Object,
    selecteds: Array,
    startGenerateBySelection: Function,
    generateBySelection: Boolean,
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      selection: false
    }
  },
  methods: {
    userHasPermission,
    generateExtract(){
      this.$emit('generateExtract', {
        selectedOnlendings: this.selecteds,
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.container-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.text-container {
  .title {
    color: var(--dark-blue);
    letter-spacing: -0.01em;
    font-weight: 700;
    font-size: 18px;
  }

  .subtitle {
    display: flex;
    flex-direction: row;
    p {
      font-weight: 700;
      font-size: 16px;
      margin-right: 20px;

      @media (max-width: 1368px) {
        font-size: 14px;
      }

      span {
        color: var(--blue-600);
        margin-right: 5px;
      }
    }
  }
}

.delete {
  padding: 4px;
  width: 56px;
  height: 36px;
  border: 2px solid var(--states-red-soft);
  border-radius: 8px;
  margin-top: 1px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.btn-title-container {
  div {
    margin-left: 15px;
  }
}

hr {
  width: 100%;
  color: var(--neutral-200);
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 1%;

  &.trash {
    fill: none;
    stroke: var(--states-red-soft);
  }
}

.tooltip-icon {
  width: 25px;
  height: 25px;
}
</style>
